import axios from 'axios'

import config from './serviceConf'

const USER_API = config.API_URL + '/api/user/'
const POINTS_API = USER_API + 'points'

const newUser = async (email, pass) => {
    const prom = await axios.post(USER_API, { email, raw_password: pass })
    return prom
}

const getPoints = async (token) => {
    const prom = await axios.get(POINTS_API, {
        headers: {
            authorization: token
        }
    })
    
    return prom
}


export {
    newUser,
    getPoints
}