import { Typography, Box, TextField, FormControl, Button } from "@mui/material"
import LoadingButton from '@mui/lab/LoadingButton'
import { useState } from "react"
import { StyledLink } from "../components/markup"

import { newUser } from "../services/user"
import { useNavigate } from "react-router-dom"

const Signup = ({ changeUser, alerts }) => {
    const [email, setMail] = useState('')
    const [pass, setPass] = useState('')

    const [isLoading, setLoading] = useState(false)
    
    const navigate = useNavigate()

    const signup = (e) => {
        if (isLoading) {
            return
        }

        e.preventDefault()
        setLoading(true)

        newUser(email, pass)
            .then(res => {
                setLoading(false)
                changeUser(res.data)
                alerts.alertChange('success', 5000, 'Sign up succeed. Thank you for joining!')
                navigate('/')
                
            })
            .catch(err => {
                setLoading(false)

                console.log(err)
                if (err && err.response && err.response.status && err.response.status === 409) {
                    return alerts.alertChange('error', 5000, 'Account with same email exists already.')
                }
                alerts.alertChange('error', 5000, 'Oops! Sign up failed. Please check all fields and try again.')  
            })
        
    }   

    return (
        <Box sx={{ 
            display: 'flex',
            alignItems: 'center', 
            justifyContent: 'center',
            flexDirection: 'column'
        }}
            component='form'
            onSubmit={signup}
        >
            <Typography variant='h2' component='h1'>Sign up</Typography>
            <Typography variant='subtitle1' component='div' color='text.secondary' textAlign='center'>Sign up to complete offers and exchange them for real cash.</Typography>

            <TextField
                type='email'
                label='Email'
                sx={{ mt: 2 }}

                value={email}
                onChange={(e) => setMail(e.target.value)}
            />

            <TextField
                type='password'
                label='Password'
                sx={{ mt: 2 }}

                value={pass}
                onChange={(e) => setPass(e.target.value)}
            />

            { isLoading ? <LoadingButton loading sx={{ mt: 2 }} variant="outlined">Sign in</LoadingButton>  : <Button type='submit' variant='contained' sx={{ mt: 2 }} color='primary'>Sign up</Button>}

            <StyledLink sx={{ mt: 1.5 }} to='/auth'>Already have an account?</StyledLink>

        </Box>
    )
}

export default Signup