import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Layout from './ui/Layout';
import '@fontsource/roboto/300.css'

import Index from './static/Index';
import Login from './static/Login';
import { AuthRequired } from './components/AuthRedirects';
import Earn from './static/Earn';
import Signup from './static/Signup';
import Logout from './static/Logout';

import Error from './static/ErrorPage'

import CaptchaEarn from './static/Captcha'
import Cashout from './static/Cashout';


const App = () => {

  const [user, setUser] = useState(window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null)

  const userChange = (userToChange) => {
    if (!userToChange) {
      setUser(null)
      window.localStorage.removeItem('user')
      return
    }

    
    setUser(userToChange)
    window.localStorage.setItem('user', JSON.stringify(userToChange))
  }

  if (user) {
    const currentDate = new Date()
    const tokenExData = new Date(user.expiresInISO)

    if (currentDate > tokenExData) {
      console.log(
        'token expired changing....'
      )
      userChange(null)
    }
  }

  const [success, setSuccess] = useState(null)
  const [warning, setWarning] = useState(null)
  const [error, setError] = useState(null)

  const alertChange = (severity, timeout, value) => {
    const stateTimeout = (stateFunc) => {
      setTimeout(() => {
        stateFunc(null)
      }, timeout)
    }

    switch (severity) {
      case 'success':
        setSuccess(value)
        stateTimeout(setSuccess)
        break

      case 'warning':
        setWarning(value)
        stateTimeout(setWarning)
        break

      default:
        setError(value)
        stateTimeout(setError)
        break
        
    }
  }

  const alerts = {
    success, setSuccess,
    warning, setWarning,
    error, setError,
    alertChange
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout user={user} alerts={alerts} />}>
            <Route index element={<Index user={user} />} />

            <Route path='auth'>
              <Route index element={<Login changeUser={userChange} alerts={alerts} />} />
              <Route path="signup" element={<Signup changeUser={userChange} alerts={alerts} />} />
              <Route path="logout" element={<AuthRequired u={user}><Logout userChange={userChange} alerts={alerts} /></AuthRequired>} />
            </Route>

            <Route path='earn'>
              <Route index element={<AuthRequired u={user}><Earn user={user} /></AuthRequired>} />
              <Route path='captcha' element={<AuthRequired u={user}><CaptchaEarn alerts={alerts} user={user} /></AuthRequired>} />
            </Route>

            <Route path='cashout'>
              <Route index element={<AuthRequired u={user}><Cashout alerts={alerts} u={user} /></AuthRequired>} />
            </Route>

            <Route path='*' element={<Error error='404' />} />
          </Route>
        </Routes>

      </BrowserRouter> 
    </>
  )
}

export default App;
