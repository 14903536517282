import { Typography } from "@mui/material"
import { StyledLink } from "../components/markup"

const Error = ({ error='Unknown error' }) => {
    return (
        <>
            <Typography variant='h2' component='h1'>{error} - We're sorry!</Typography>
            <Typography paragraph>Click <StyledLink to='/'>here to get into the homepage</StyledLink>.</Typography>
        </>
    )
}   

export default Error