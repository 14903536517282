import { Avatar, Box, Button, Grid, Icon, Link, TextField, Typography } from "@mui/material"
import LoadingButton from '@mui/lab/LoadingButton'

import { useState } from "react"; 
import { loginPost } from "../services/login";
import { StyledLink } from "../components/markup";
import { useNavigate } from "react-router-dom";

const Login = ({ alerts, changeUser }) => {
    const [email, setMail] = useState('')
    const [pass, setPass] = useState('')

    const [isLoading, setLoading] = useState(false)
    const navigate = useNavigate()

    const login = async (e) => {
        e.preventDefault()
        setLoading(true)
        loginPost(email, pass)
            .then(res => {
                const user = res.data

                changeUser(user)
                setLoading(false)

                navigate('/earn')

            })
            .catch(err => {
                console.error(err)
                setLoading(false)


                if (err && err.response && err.response.status && err.response.status !== 403) {
                    return alerts.alertChange('error', 5000, 'Oops! Something happened. Please try again.')
                }

                
                if (!err.response) {
            
                    return alerts.alertChange('error', 5000, 'Oops! Server error.')
                }


                alerts.alertChange('error', 3000, 'Invalid email or password')
            })
    }

    return (
        <>
            <Box component='form' sx={{ 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
            }} onSubmit={login}>

                <Typography variant='h2' component='h1'>Sign in</Typography>
                <Typography variant='subtitle1' color='text.secondary' gutterBottom textAlign='center'>Sign in with your existing account to go back to earning.</Typography>


                        <TextField
                            label='Email'
                            type='email'
                            value={email}
                            onChange={(e) => setMail(e.target.value)}  
                            sx={{ mb: 1, mt: 1 }}
                        />


                        <TextField
                            sx={{ mt: 2, mb: 1 }}
                            label='Password'
                            type='password'
                            value={pass}
                            onChange={(e) => setPass(e.target.value)}
                        />


                        {
                            isLoading 
                                ? <LoadingButton loading sx={{ mt: 1 }} variant="outlined">Sign in</LoadingButton> 
                                : <Button variant='contained' sx={{ mt: 1 }} color='primary' type='submit'>Sign in</Button> 
                        }

                        <StyledLink sx={{ mt: 2 }} to='/auth/signup'>Don't have an account?</StyledLink>
            </Box>
        
        </>
    )
}

export default Login