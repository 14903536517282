import HCaptcha from "@hcaptcha/react-hcaptcha"
import { Button, Card, CardActionArea, CardContent, LinearProgress, Paper, Typography } from "@mui/material"
import { useState } from "react"
import { useRef } from "react"
import { sendCaptcha } from "../services/captcha"

const CaptchaEarn = (props) => {
    return (
        <>
            <Typography variant='h3' component='h1'>Earn by completing captchas</Typography>
            <CaptchaComplete {...props} />
            
        </>
    )
}

const CaptchaComplete = ({ user, alerts }) => {
    const [captchaKey, setKey] = useState(null)
    const [isLoading, setLoading] = useState(false)

    const captchaOk = (token) => {
        setKey(token)
    }

    const submitCaptcha = () => {
        setLoading(true)
        sendCaptcha(captchaKey, user.token)
            .then(res => {
                const { pointsAmount } = res.data

                alerts.alertChange('success', 3000, `You have been rewarded succesfully. You have now ${pointsAmount} points.`)
                setLoading(false)
            })
            .catch(err => {
                alerts.alertChange('error', 3000, "Something happened with the completion of the captcha.")
                console.error(err)
                setLoading(false)
            })
    }

    const captchaRef = useRef()

    return (
        <Card>
            <CardContent>
                <Typography variant='h5' sx={{ mb: 1 }}>Complete captcha</Typography>
                {
                    isLoading ? <LinearProgress sx={{ mb: 2, maxWidth: '75%' }} /> : (
                        <HCaptcha
                            sitekey="65fb26b7-ec8f-4d58-bd21-1e08de13323f"
                            onVerify={captchaOk}
                            theme="dark"
                            ref={captchaRef}
                        />
                    )
                }

                
                <Button
                    variant="outlined"
                    color="primary"
                    disabled={!captchaKey || isLoading}
                    onClick={() => {
                        submitCaptcha()
                        captchaRef.current?.resetCaptcha()
                    }}
                >
                    Submit
                </Button>


            </CardContent>
        </Card>
    )
}


export default CaptchaEarn