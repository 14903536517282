import { AppBar, IconButton, Toolbar, Typography, Container, Menu, MenuItem, createTheme, Divider, ThemeProvider, CssBaseline } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { userMenuItems } from '../routes';

import { NoCssLink } from '../components/markup'

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import AlertBars from '../components/AlertBars';

const theme = createTheme({
    palette: {
        mode: 'dark'
    }
})
const primaryTextColor = theme.palette.text.primary

const Layout = ({ user, alerts }) => {
    const [userAnchor, setUserAnchor] = useState(null) 

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppBar position="static">
                <Toolbar>
                    <NoCssLink sx={{ flexGrow: 1 }} to='/'>
                        
                        <Typography variant='h6' component='div'>
                            Fastycash
                        </Typography>

                    </NoCssLink>

                    <IconButton onClick={(e) => setUserAnchor(e.target)}>
                        <AccountCircleIcon />
                    </IconButton>

                    <UserMenu user={user} anchor={userAnchor} closeAnchor={() => setUserAnchor(null)} />

                </Toolbar>
            </AppBar> 

            <AlertBars {...alerts} />


            <Container sx={{ mt: 2 }}>
                <Outlet />
            </Container>       
        </ThemeProvider>
    )
}

const UserMenu = ({ anchor, closeAnchor, user }) => {


    return (
        <Menu
            sx={{}}
            anchorEl={anchor}
            anchorOrigin={{ 
                vertical: 'top',
                horizontal: 'right'
            }}        
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}

            open={Boolean(anchor)}
            onClose={closeAnchor}
        > 
            {userMenuItems.map(item => (
                <NoCssLink color={primaryTextColor} to={item.to} key={item.to}>
                    <MenuItem onClick={closeAnchor}>
                        <Typography textAlign='center'>{item.name}</Typography>
                    </MenuItem>
                </NoCssLink> 
            ))}
            <Divider />
            <NoCssLink color={primaryTextColor} to={user ? '/auth/logout' : '/auth'}>
                <MenuItem onClick={closeAnchor}>
                    <Typography textAlign='center'>{user ? 'Logout' : 'Login'}</Typography>
                </MenuItem>
            </NoCssLink>
        </Menu>
    )
}

export default Layout