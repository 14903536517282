import { Typography, Button } from "@mui/material"
import { NoCssLink } from "../components/markup"
import { Link as RouterLink } from "react-router-dom"

const offersAvailable = true

const Earn = ({ user }) => {
    return (
        <>
            <Typography variant='h2'>Best Fastycash offers</Typography>
            <Typography variant='subtitle1'>Select one of these to earn points.</Typography>

            { user ? <Offers user={user} /> : (
                <>
                    <Typography variant='h5' component='p'>You have to <NoCssLink to='/auth'>login</NoCssLink> or <NoCssLink to='/auth/signup'>signup</NoCssLink> to access offers and earn. </Typography>
                </>
            ) }

        </>
    )   
}

const Offers = ({ user }) => {
    if (!offersAvailable) {
        return <Typography paragraph>No offers available.</Typography>
    }

    return (
        <Button variant="contained" sx={{ mt: 1 }} component={RouterLink} to='/earn/captcha'>
            Complete captchass
        </Button>
    )
}

export default Earn