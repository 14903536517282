import axios from 'axios'

import config from './serviceConf'
const CASHOUT_API = config.API_URL + '/api/cashout/'

const reqCashout = async (token, pointsAmount) => {
    const prom = await axios.post(CASHOUT_API, {
        pointsToWithdraw: pointsAmount
    }, {
        headers: {
            authorization: token
        }
    })

    return prom

}

export {
    reqCashout
}