import { Alert } from '@mui/material'

const AlertBars = ({ success, warning, error }) => {

    const barSx = {
        mt: 1
    }

    return (
        <>
            {success ? (
            <Alert
                severity="success"
                sx={barSx}
            >
                {success}
            </Alert>
            ) : null}

            {warning ? (
                <Alert
                    severity="warning"
                    sx={barSx}
                >
                    {warning}
                </Alert>
            ) : null}


            {error ? (
                <Alert
                    severity="error"
                    sx={barSx}
                >
                    {error}
                </Alert>
            ) : null}


        </>
    )
}

export default AlertBars