import { accordionSummaryClasses, Box, Button, LinearProgress, Paper, Slider, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { useEffect } from "react"
import { reqCashout } from "../services/cashout"
import { getPoints } from "../services/user"

const MIN_CASHOUT = 1000

const Cashout = ({ u, alerts }) => {
    const [points, setPoints] = useState(null)

    useEffect(() => {
        getPoints(u.token)
            .then(res => {
                setPoints(res.data.points)
            })
            .catch(err => {
                console.error(err)
            })
    }, [])

    return (
        <Box>
            <Typography variant='h1' component='h1'>
                Cashout
            </Typography>
            
            {
                points !== null ? <PayPalBox u={u} points={points} alerts={alerts} /> : <LinearProgress />
            }
        </Box>
    )
}

const PayPalBox = ({ u, points, alerts }) => {
    const [sliderValue, setSlider] = useState(points)
    const [isLoading, setLoading] = useState(false)

    const isAbleToCashout = points > MIN_CASHOUT && sliderValue > MIN_CASHOUT
        
    const request = async () => {
        setLoading(true)
        reqCashout(u.token, sliderValue)
            .then(res => {
                alerts.alertChange('success', 3000, 'Your withdrawn request has been succesfully sent. Your request will be completed in couple of days.')
                setLoading(false)
            })
            .catch(err => {
                alerts.alertChange('error', 3000, 'Something went wrong with your withdraw. Please contact us if this problem persists.')
                setLoading(false)
            })
    }

    return (
        <>
            <Typography variant='h5' component='h2' gutterBottom>Cashout with PayPal</Typography>

            <Paper sx={{ padding: 2.5 }}>
                <Typography variant='h6' component='p' gutterBottom>Email</Typography>
                <TextField
                    fullWidth
                    sx={{ maxWidth: '27.5rem' }}
                    value={u.email}
                    InputProps={{
                        readOnly: true
                    }}
                />

                <Typography sx={{ mt: 1 }} variant='h6' component='p' gutterBottom>
                    Points to be withdrawn
                </Typography>
                
                <Slider
                    aria-label="Points"
                    min={1}
                    max={points}
                    valueLabelDisplay="auto"
                    value={sliderValue}
                    onChange={(e) => setSlider(e.target.value)}
                    sx={{ maxWidth: '27.5rem' }}
                    
                />
                <Typography variant='body1'>{sliderValue} points equals {sliderValue / 10000} €.</Typography>

                {   
                    isLoading ? <LinearProgress sx={{ mt: 1, maxWidth: '20rem' }} /> : (
                    <Button disabled={!isAbleToCashout} variant='outlined' sx={{ mt: 1 }} onClick={request}>
                        Request cashout
                    </Button>)
                }

                {
                    !isAbleToCashout && <Typography paragraph color='text.secondary' sx={{ mt: 1 }}>Your must have atleast {MIN_CASHOUT} points to be able to send a cashout request.</Typography>
                }


            </Paper>

            <Typography
                variant='h4'
                component='h2'
                sx={{ mt: 2 }}
                gutterBottom
            >
                What happens after sending a cashout request?
            </Typography>

            <Typography
                paragraph
            >
                Your request will be added to our cashout list. We will make sure that everything is okay and after that the payment will be paid to your personal PayPal account.
                Usually the cash will be in your account in a couple of days. 
            </Typography>
        </>

    )
}

export default Cashout