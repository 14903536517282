const userMenuItems = [
    {
        'name': 'Earn',
        'to': '/earn'
    },
    {
        'name': 'Cashout',
        'to': '/cashout'
    }
]

export {
    userMenuItems
}