import { Box, Link as MuiLink } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"

const NoCssLink = (props) => {
    return (
        <Box sx={props.sx}>
            <RouterLink {...props} style={{ textDecoration: 'none', color: props.color ? props.color : 'white'}} />
        </Box>
    )
}

const StyledLink = (props) => {
    return (
        <MuiLink component={RouterLink} {...props} style={{ textDecoration: 'underline' }} />
    )
}

export { NoCssLink, StyledLink }