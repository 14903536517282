import config from './serviceConf'
import axios from 'axios'

const API_URL = config.API_URL
const LOGIN_URL = API_URL + '/api/login/'

const loginPost = async (email, password) => {
    const prom = await axios.post(LOGIN_URL, { email, raw_password: password })  
    return prom
}

export {
    loginPost
}