import { Button, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"

const Logout = ({ userChange, alerts }) => {
    const navigate = useNavigate()

    const logout = () => {
        userChange(null)
        alerts.alertChange('success', 5000, 'Logout succeed.')
        navigate(
            '/'
        )
    }

    return (
        <>
            <Typography variant='h2' component='h1'>Logout</Typography>
            <Typography paragraph>On this site you can log out from Fastycash.</Typography>

            <Button variant='contained' color='error' onClick={logout}>Logout</Button>
        </>
    )
}

export default Logout