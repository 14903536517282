import { Button, Card, CardActions, CardContent, Grow, Link, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useTranslation } from "react-i18next"
import { Link as RouterLink } from "react-router-dom"
import { NoCssLink } from "../components/markup"

const Index = ({ user }) => {

    return (
        <>
            <IndexSlide />
            
            {
                !user ?
                    <Card sx={{ mt: 2 }}>
                        <CardContent>
                            <Typography variant='h4' compoennt='h2'>How it works?</Typography>
                            <Typography paragraph>You complete offers like answering surveys, playing games, and watching videos. From these offers, you will earn points that you can cash out to your PayPal account. Does it sound good? Let's start!</Typography>                    
                        </CardContent>
                        
                        <AuthMenu />
                    
                    </Card>
                    : <StartEarningView />
                
            }

            <Typography
                variant='h5'
                component='h3'
                sx={{ mt: 2 }}
            >
                Beta & demo warning
            </Typography>

            <Typography
                paragraph
                color='text.secondary'
            >
                Fastycash.ga is still at its beta phase. All functions may not work properly. Fastycash is not meant yet for public use. Please notify all bugs and unexpected UI changes to <Link href="mailto:support@fastycash.ga">support@fastycash.ga</Link>.
            </Typography>




        </>
    )
}

const StartEarningView = () => {
    return (
        <>
            <Button
                variant="contained"
                sx={{ mt: 1 }}
                component={RouterLink}
                to='/earn/captcha'
            >
                Complete captchas
            </Button>
        </>
    )
}

const IndexSlide = () => {
    const { t } = useTranslation()

    return (
        <Box>
            <Grow in>
                <Box>
                    <Typography variant='h2' component='h1' i18nKey='indexHeader'>Earn by filling out captchas.</Typography>
                    <Typography variant='subtitle1' component='div'>Give your opinion to AI developers and you get rewarded - real money.</Typography>
                </Box>
            </Grow>
        </Box>
    )
}

const AuthMenu = () => {
    return (
        <CardActions sx={{ mt: -3 }}>
            {/*<Button variant='contained' color='primary'>Sign in with Google</Button>*/}
            <NoCssLink to='/auth/signup'>
            <Button variant='outlined' color='primary' sx={{ ml: 1 }}>Sign up</Button>
            </NoCssLink>

            <NoCssLink sx={{ ml: 1 }} to='/auth'>
            <Button color='secondary' variant="outlined" to='/auth'>Sign in</Button>
            </NoCssLink>
        </CardActions>
    )
}

export default Index