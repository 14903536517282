import axios from 'axios'

import config from './serviceConf'
const CAPTCHA_API = config.API_URL + '/api/captcha/'

const sendCaptcha = async (captchaKey, token) => {
    const req = await axios.post(CAPTCHA_API, {
        captchaKey
    }, 
    {
        headers: {
            authorization: token
        }
    })

    return req
}

export {
    sendCaptcha
}