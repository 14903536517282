// components for redirecting users based on their
// authentication statuses

import { useNavigate } from "react-router-dom"
import { useEffect } from "react"

const AuthRequired = ({ u, children }) => {
    const navigate = useNavigate()

    useEffect(() => {
        if (!u)
        {
            navigate('/auth')
        }
    }, [u, navigate])
    
    return (
        {...children}
    )
}

const LogoutRequired = ({ u, children }) => {
    const navigate = useNavigate()

    if (u) {
        return navigate('/')
    }

    return (
        {...children}
    )
}

export {
    AuthRequired,
    LogoutRequired
}